import NavComponent from "components/Navigation/NavComponent";
import PaymentContext from "context/PaymentContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import PaidListUser from "Pages/dashboard/PureBrand/Payments/PaidListUser";
import PaymentListUser from "Pages/dashboard/PureBrand/Payments/PaymentListUser";
import React, { useContext, useState } from "react";
import { Spinner, Table, Tabs } from "react-bootstrap";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import FailedListUser from "Pages/dashboard/PureBrand/Payments/FailedListUser";
import { Button, message } from 'antd';
import { downloadAccountingData } from 'Services/storeService';
import './paymentStoreList.css';
import moment from 'moment';
import { GENERIC_ERROR_MESSAGE } from "utils/constants";

const PaymentStoreList = () => {
  const {
    payToHostlist,
    adminPaymentList,
    prevStep,
    nextStep,
    prevStepPaid,
    nextStepPaid,
    prevStepFailed,
    nextStepFailed,
    payToHostlistPaid,
    payToHostlistFailed,
    adminPaymentListPaid,
    adminPaymentListFailed,
  } = useContext(PaymentContext);
  const [loading, setLoading] = useState(true);
  const [loadingPros, setLoadingPros] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();


  /**
   * This function is used to get the text of the download button
   *
   * @param {boolean} paid determines whether it is for paid revenues tab or not
   * @param {boolean} failed determines whether it is for failed revenues tab or not
   * @return {string} Text to be displayed on the download button
   */


  const getDownloadButtonText = (paid, failed) => {
    if (paid) {
      return 'Download Paid Revenues Data';
    } else if (failed) {
      return 'Download Failed Revenues Data';
    } else {
      return 'Download Unpaid Revenues Data';
    }
  };

  /**
   * This function is used to download the accounting data based on the active tab
   *
   * @param {boolean} paid determines whether it is for paid revenues tab or not
   * @param {boolean} failed determines whether it is for failed revenues tab or not
   * @return {void}
   */

  const downloadData = async (paid, failed) => {
    try {

      let data;
      let fileName;

      if (paid) {
        data = {
          order: 'true',
          paid: 'true',
          failed: 'false',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        fileName = 'Paid Revenues'
      } else if (failed) {
        data = {
          order: 'true',
          paid: 'false',
          failed: 'true',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        fileName = 'Failed Revenues'
      } else {
        data = {
          order: 'true',
          paid: 'false',
          failed: 'false',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        fileName = 'Unpaid Revenues'
      }


      setDownloadLoading(true);
      const res = await downloadAccountingData(data);

      const downloadURL = URL.createObjectURL(res.data);
      const a = document.createElement('a');
      a.href = downloadURL;
      a.style.display = 'none';
      a.download = `${fileName}-${moment().format('MM-DD-YYYY')}.csv`;
      a.target = '_blank';
      document.body.append(a);
      a.click();
      URL.revokeObjectURL(downloadURL);
      document.body.removeChild(a);

      setDownloadLoading(false);
    } catch (err) {
      setDownloadLoading(false);
      messageApi.open({
        type: 'error',
        content:
          err?.response?.data?.message?.phone_number ||
          err?.message ||
          GENERIC_ERROR_MESSAGE,
        duration: 5,
      });
    }
  };

  const getlist = (type) => {
    if (loadingPros) {
      return (
        <tr>
          <td colSpan={8}>
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" />
              <Spinner animation="grow" />
              <Spinner animation="grow" />
            </div>
          </td>
        </tr>
      );
    } else {
      if (type === "demo") {
        if (adminPaymentList.length < 1) {
          return (
            <tr>
              <td className="no-data-wrap">No data available yet!</td>
            </tr>
          );
        } else {
          return adminPaymentList?.map((adminPaymentList, index) => {
            return (
              <PaymentListUser key={index} hostDetails={adminPaymentList} />
            );
          });
        }
      } else if (type === "paid") {
        if (adminPaymentListPaid.length < 1) {
          return (
            <tr>
              <td className="no-data-wrap">No data available yet!</td>
            </tr>
          );
        } else {
          return adminPaymentListPaid?.map((adminPaymentListPaid, index) => {
            return (
              <PaidListUser key={index} hostDetails={adminPaymentListPaid} />
            );
          });
        }
      } else if (type === "failed") {
        if (adminPaymentListFailed.length < 1) {
          return (
            <tr>
              <td className="no-data-wrap">No data available yet!</td>
            </tr>
          );
        } else {
          return adminPaymentListFailed?.map(
            (adminPaymentListFailed, index) => {
              return (
                <FailedListUser
                  key={index}
                  hostDetails={adminPaymentListFailed}
                />
              );
            }
          );
        }
      }
    }
  };
  useEffectOnce(() => {
    let first = payToHostlist(`?order=true&paid=false`);
    let second = payToHostlistPaid(`?order=true&paid=true`);
    let third = payToHostlistFailed(`?order=true&paid=false&failed=true`);
    const promise = Promise.all([first, second, third]);
    promise.then(() => {
      setLoading(false);
      setLoadingPros(false);
    });
  }, []);

  if (loading === true) {
    return <PurebrandLoader />;
  }

  return (
    <div>
      {contextHolder}
      <Tabs
        defaultActiveKey="unpaid"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <NavComponent
          eventKey="unpaid"
          title="Unpaid Revenues"
          className=" payout-list-wrapper"
        >
          <div className="inner-tab-payout">
            <Table responsive className="table-payout">
              <thead>
                <tr>
                  <th className="storename-head">Full name</th>
                  <th className="payment-head">Store name</th>
                  <th className="receive-head">Product</th>
                  <th className="receive-head">Comission type</th>
                  <th className="receive-head price-head">Amount</th>
                  {/* <th className="receive-head">Status</th> */}
                  <th className="receive-head">Guest Name</th>
                  <th className="receive-head">Demo Date</th>
                </tr>
              </thead>
              <tbody>{getlist("demo")}</tbody>
            </Table>
            <div className="button-wrapper-product text-center my-4">
              <button
                onClick={() => {
                  setLoadingPros(true);
                  let res = payToHostlist(new URL(prevStep).search);
                  res.then(() => setLoadingPros(false));
                }}
                className="btn btn-outline-primary previous-button"
                disabled={prevStep === null}
              >
                Prev
              </button>
              <button
                onClick={() => {
                  setLoadingPros(true);
                  let res = payToHostlist(new URL(nextStep).search);
                  res.then(() => setLoadingPros(false));
                }}
                className="btn btn-outline-primary previous-button"
                disabled={nextStep === null}
              >
                Next
              </button>
            </div>
            <div className="download-data-button">
              <Button
                onClick={() => {
                  downloadData(false, false);
                }}
                loading={downloadLoading}
                className="download-data-btn"
              >
                {getDownloadButtonText(false, false)}
              </Button>
            </div>
          </div>
        </NavComponent>
        <NavComponent
          eventKey="paid"
          title="Paid Revenues"
          className=" payout-list-wrapper"
        >
          <Table responsive className="table-payout">
            <thead>
              <tr>
                <th className="storename-head">Full name</th>
                <th className="payment-head">Store name</th>
                <th className="receive-head">Product</th>
                <th className="receive-head">Comission type</th>
                <th className="receive-head price-head">Amount</th>
                {/* <th className="receive-head">Status</th> */}
                <th className="receive-head">Guest Name</th>
                <th className="receive-head">Demo Date</th>
              </tr>
            </thead>
            <tbody>{getlist("paid")}</tbody>
          </Table>
          <div className="button-wrapper-product text-center my-4">
            <button
              onClick={() => {
                setLoadingPros(true);
                let res = payToHostlistPaid(new URL(prevStepPaid).search);
                res.then(() => setLoadingPros(false));
              }}
              className="btn btn-outline-primary previous-button"
              disabled={prevStepPaid === null}
            >
              Prev
            </button>
            <button
              onClick={() => {
                setLoadingPros(true);
                let res = payToHostlistPaid(new URL(nextStepPaid).search);
                res.then(() => setLoadingPros(false));
              }}
              className="btn btn-outline-primary previous-button"
              disabled={nextStepPaid === null}
            >
              Next
            </button>
            <div className="download-data-button">
              <Button
                onClick={() => {
                  downloadData(true, false);
                }}
                loading={downloadLoading}
                className="download-data-btn"
              >
                {getDownloadButtonText(true, false)}
              </Button>
            </div>
          </div>
        </NavComponent>
        <NavComponent
          eventKey="failed"
          title="Failed Transactions"
          className="payout-list-wrapper"
        >
          <Table responsive className="table-payout">
            <thead>
              <tr>
                <th className="storename-head">Full name</th>
                <th className="payment-head">Store name</th>
                <th className="receive-head">Product</th>
                <th className="receive-head">Comission type</th>
                <th className="receive-head price-head">Amount</th>
                {/* <th className="receive-head">Status</th> */}
                <th className="receive-head">Guest Name</th>
                <th className="receive-head">Demo Date</th>
                <th className="receive-head">Reason</th>
              </tr>
            </thead>
            <tbody>{getlist("failed")}</tbody>
          </Table>
          <div className="button-wrapper-product text-center my-4">
            <button
              onClick={() => {
                setLoadingPros(true);
                let res = payToHostlistFailed(new URL(prevStepFailed).search);
                res.then(() => setLoadingPros(false));
              }}
              className="btn btn-outline-primary previous-button"
              disabled={prevStepFailed === null}
            >
              Prev
            </button>
            <button
              onClick={() => {
                setLoadingPros(true);
                let res = payToHostlistFailed(new URL(nextStepFailed).search);
                res.then(() => setLoadingPros(false));
              }}
              className="btn btn-outline-primary previous-button"
              disabled={nextStepFailed === null}
            >
              Next
            </button>
            <div className="download-data-button">
              <Button
                onClick={() => {
                  downloadData(false, true);
                }}
                loading={downloadLoading}
                className="download-data-btn"
              >
                {getDownloadButtonText(false, true)}
              </Button>
            </div>
          </div>
        </NavComponent>
      </Tabs>
    </div>
  );
};

export default PaymentStoreList;
