import React from "react";
import { failedStatuses } from "utils/constants";
import { utcLocalTimeStringConversion } from "utils/dateFormatter";

const FailedListUser = (props) => {
  return (
    <>
      {(props?.hostDetails.earning.earning_paid === null && failedStatuses.some((status) =>
            props?.hostDetails?.earning?.stripe_payment_status.includes(status) || props?.hostDetails?.earning?.dots_payment_status.includes(status)
          )) && (
        <tr>
          <td>
            {props?.hostDetails.members.host_detail.first_name}{" "}
            {(props?.hostDetails.members.host_detail.last_name).charAt(0)}.
          </td>
          <td>{props?.hostDetails.product.store.name}</td>
          <td>{props?.hostDetails.product.name}</td>
          <td>Demo Comission</td>
          <td>{props?.hostDetails.earning.earning}</td>
          <td>{props?.hostDetails?.members?.guest_detail?.full_name}</td>
          <td>{utcLocalTimeStringConversion(props?.hostDetails?.ended_at)}</td>
          <td>
            <p className="admin-payment-popup text-danger">
              {props?.hostDetails?.earning?.dots_payment_status !== 'not_initiated' ? props?.hostDetails?.earning?.dots_payment_status: props?.hostDetails?.earning?.stripe_payment_status}
            </p>
          </td>
        </tr>
      )}

      {props?.hostDetails.earning.sales_commission !== "0.00" && (
        <>
          {(props?.hostDetails.earning.sales_commission_paid === null && failedStatuses.some((status) =>
            props?.hostDetails?.earning?.stripe_sales_payment_status.includes(
              status
            ) || props?.hostDetails?.earning?.dots_sales_payment_status.includes(
              status
            )
          )) && (
            <tr>
              <td>
                {props?.hostDetails.members.host_detail.first_name}{" "}
                {(props?.hostDetails.members.host_detail.last_name).charAt(0)}.
              </td>
              <td>{props?.hostDetails.product.store.name}</td>
              <td>{props?.hostDetails.product.name}</td>
              <td>Sales Comission</td>
              <td>{props?.hostDetails.earning.sales_commission}</td>
              <td>{props?.hostDetails?.members?.guest_detail?.full_name}</td>
              <td>
                {utcLocalTimeStringConversion(props?.hostDetails?.ended_at)}
              </td>
              <td>
                <p className="admin-payment-popup text-danger">
                  {props?.hostDetails?.earning?.dots_sales_payment_status !== "not_initiated" ? props?.hostDetails?.earning?.dots_sales_payment_status : props?.hostDetails?.earning?.stripe_sales_payment_status}
                </p>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );
};

export default FailedListUser;
