export const BASE_URL = `https://api.purebrand.io`;
export const SHOPIFY_URL = `https://app.purebrand.io`;

// export const BASE_URL = `http://192.168.1.4:8000`;

export const DOTS_CONNECT_STATUS = {
  NOT_CONNECTED: 'Not Connected',
  UNVERIFIED: 'Unverified',
  INCOMPLETE_FLOW: 'Incomplete Flow',
  COMPLETE_FLOW: 'Complete Flow',
};

export const TEST_ROLES = {
  COMBOBOX: 'combobox',
  OPTION: 'option',
};

export const DEMO_HISTORY_STATUS = {
  SUCCESS: 'Success',
  GUEST_NO_SHOW: 'Guest No Show',
  UNDER_TIME: 'Under Time',
  BACKUP_SUCCESS: 'Back-Up Success',
};

export const DATES_FILTER = [
  { label: 'Today', value: 'today' },
  { label: 'Last 7 Days', value: 'week' },
  { label: 'Last 14 Days', value: 'biweek' },
  { label: 'Last 30 Days', value: 'month' },
  { label: 'Pay Period', value: 'payperiod' },
  { label: 'Custom', value: 'custom' },
];

export const DATE_FORMATS = {
  GENERIC_DATE_FORMAT: 'YYYY-MM-DD',
  GENERIC_UTC_DATE_FORMAT: 'YYYY-M-DTHH:mm',
  GENERIC_UTC_TIME_FORMAT: 'hh:mm A',
  GENERIC_FULL_DATE_FORMAT: 'YYYY-M-DTHH:mm:ss',
  MY_PAYOUTS_DATE_FORMAT: 'ddd MMM DD YYYY'
};

export const SORTING_STATUS = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
  NONE: 'none',
};

export const APP_STATUS = {
  CREATED: 'created',
  IN_REVIEW: 'in_review',
  APPROVED: 'approved',
};

export const GENERIC_ERROR_MESSAGE = 'Something went wrong';

export const US_STATE_LIST = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansa', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Lousiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const INCORPORATION_TYPES = [
  {
    label: 'Sole proprietorship',
    value: 'sole_proprietorship',
  },
  {
    label: 'Partnership',
    value: 'partnership',
  },
  {
    label: 'Limited Liability Company',
    value: 'llc',
  },
  {
    label: 'C Corporation',
    value: 'c_corporation',
  },
  {
    label: 'S Corporation',
    value: 's_corporation',
  },
];

export const COUNTRY_OPTIONS = [
  {
    label: 'United States',
    value: 'United States',
  },
];

export const DEMO_STATUS_OPTIONS = {
  UNDECIDED: 'UNDECIDED',
  ACCEPTED: 'ACCEPTED',
  STARTED: 'STARTED',
  DECLINED: 'DECLINED',
};

export const failedStatuses = [
  'card_declined',
  'payment_blocked',
  'expired_card',
  'error_creating_intent',
  'payout_failed',
  'failed',
];
