import LoadingError from "Pages/Error/LoadingError";
import PurebrandLoader from "components/Loader/PurebrandLoader";
import DataContext from "context/DataContext";
import { useEffectOnce } from "hooks/useEffectOnce";
import React, { useContext, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "./publicprofile.css";
import { ProfilePicture } from "assets/images";
import PublicVideoListing, { PublicPhotoListing } from "./PublicVideoListing";

const ProfilePublic = () => {
  const { getPublicUserProfile } = useContext(DataContext);
  let location = useLocation();
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState(null);

  const checkedits = async () => {
    try {
      let res = await getPublicUserProfile(location.pathname.split("/")[2]);
      setLoading(false);
      setError(null);
      setProfileData(res.data.data);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const getUserImages = () => {
    return profileData.user_product.map((product) => {
      return product.media.map((media, i) => (
        <PublicPhotoListing key={i} medias={media} />
      ));
    });
  };

  const getProductImages = () => {
    return profileData?.user_product?.map((product, i) => {
      return (
        <img
          key={i}
          alt="product_image"
          className="imageA d-flex"
          style={{
            height: "250px",
            width: "auto",
            padding: "10px",
          }}
          src={product?.product?.image}
        />
      );
    });
  };

  const getUserVideos = () => {
    return profileData.user_product.map((product) => {
      return product.media.map((media, i) => (
        <PublicVideoListing key={i} medias={media} />
      ));
    });
  };
  useEffectOnce(() => {
    if (location.pathname.split("/")[2]) {
      checkedits();
    }

    //   return () => {

    //   }
  });
  if (loading) {
    return <PurebrandLoader />;
  } else if (error) {
    return (
      <LoadingError
        status={error?.response?.status}
        toUrl={"/"}
        message={
          error?.response?.data?.message
            ? error.response.data.message
            : "Some error occured while fetching the data."
        }
      />
    );
  } else {
    return (
      <Container>
        <header>
          <div className="profile-wrap">
            <div className="d-flex">
              <div className="profile-image">
                <img
                  src={
                    profileData?.members.host_detail.profile_picture.file
                      ? profileData.members.host_detail.profile_picture.file
                      : ProfilePicture
                  }
                  alt="Profile"
                  className="imageA"
                  style={{
                    height: "172px",
                    width: "172px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className="profile-user-settings">
                <h1
                  className="profile-user-name"
                  style={{ marginTop: "50px", paddingLeft: "20px" }}
                >
                  {profileData?.members.host_detail.first_name}{" "}
                  {profileData?.members.host_detail.last_name.charAt(0)}.
                </h1>
              </div>
            </div>
            <div className="profile-stats pt-5">
              <h5 className="profile-stat-count">Product List</h5>
              {profileData?.user_product.map((product, i) => (
                <p key={i}>- {product.product.name}</p>
              ))}
              <h5 className="profile-stat-count">
                Store
                <p>- {profileData?.user_product[0].product.store.name}</p>
              </h5>
            </div>

            <div className="profile-bio">
              <h5 className="profile-stat-count">Description </h5>
              {profileData?.user_product.map((product, i) => (
                <p key={i}>
                  <strong>- {product.product.name}:</strong>
                  {product.description}
                </p>
              ))}
            </div>
          </div>
        </header>
        <hr />

        <div className="container">
          {profileData?.user_product[0]?.media[0]?.picture === null ? (
            <div className="gallery row photos-collection">
              {getProductImages()}
            </div>
          ) : (
            <>
              <div className="gallery row photos-collection">
                {getUserImages()}
              </div>
              <hr />
              <div className="gallery row video-colletion mt-3">
                {getUserVideos()}
              </div>
            </>
          )}
        </div>
      </Container>
    );
  }
};

export default ProfilePublic;
