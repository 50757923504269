import DemoContext from "context/DemoContext";
import VideoContext from "context/VideoContext";
import React, { useContext, useEffect, useState } from "react";
import { Alert, Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  calculateDifferenceTodayPublic,
  convertUTCToLocal,
} from "utils/dateFormatter";
import "./publicprofile.css";
import { Rating } from "react-simple-star-rating";
import ApiContext from "context/ApiContext";
import { Field, Form, Formik } from "formik";
import { DEMO_STATUS_OPTIONS } from "utils/constants";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}/${year}`;
};
const convertTime = (timeString) => {
  const [hours, minutes] = timeString.split(":");
  const suffix = hours >= 12 ? "PM" : "AM";
  let formattedHours = hours % 12 || 12;
  formattedHours = formattedHours < 10 ? `0${formattedHours}` : formattedHours;
  const formattedMinutes =
    minutes < 10 && minutes > 0 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${suffix}`;
};

const MeetingStatusPublic = () => {
  const { publicDemoStatus } = useContext(DemoContext);
  const { setDemoId, setVideoCredentials } = useContext(VideoContext);
  const { hostGuestRating } = useContext(ApiContext);
  const [demoInfo, setDemoInfo] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const [demoIdentifier, setDemoIdentifier] = useState(
    queryParams.get("demo") ? queryParams.get("demo") : ""
  );
  const [responseMessage, setResponseMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const checkReferencee = async (param) => {
    setLoading(true);
    try {
      const res = await publicDemoStatus(param);
      setDemoInfo(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (queryParams.get("demo")) {
      checkReferencee(queryParams.get("demo"));
    }
  }, []);

  return (
    <Container className="mt-4">
      <div className="row">
        <div className="schedule-image-wrapper d-flex justify-content-center col-12 col-sm-12 col-md-3 col-lg-3">
          <img
            src="https://cdn.shopify.com/extensions/90266465-eaf3-4ba9-945b-37b7bb21320c/11.27.0/assets/murphy-img.png"
            alt=""
          />
        </div>
        <div className="schedule-text-content col-12 col-sm-12 col-md-4 col-lg-4">
          <div className="heading-wrapper">Product Demo</div>
          <div className="detail-wrapper">
            <p>
              Connect with previous customers to get honest feedback about our
              products and services and get discounts on your purchase!
            </p>
            <br />
            <p>Hosted by</p>
          </div>
          <div className="logo-wrapper">
            <img
              src="https://cdn.shopify.com/extensions/90266465-eaf3-4ba9-945b-37b7bb21320c/11.27.0/assets/purebrand-guest-logo.png"
              alt="purebrand"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
        <div className="demo-button-wrapper col-12 col-sm-12 col-md-5 col-lg-5">
          <div className="enabled-video-wrapper">
            {/* <div className="status-check-title">
              
            </div> */}
            <label htmlFor="demo-reference-id">
              Enter the reference id to check demo status.
            </label>
            <div className="reference-wrapper mt-3">
              <div className="form-group">
                <input
                  type="text"
                  id="demo-reference-id"
                  name="demo-reference-id"
                  placeholder="Reference ID"
                  className="form-control"
                  value={demoIdentifier}
                  onChange={(e) => setDemoIdentifier(e.target.value)}
                />
              </div>

              <div className="button_wrapper d-flex is-flex is-flex-wrap">
                <button
                  id="check-demo-status"
                  className="btn btn-primary mt-3"
                  onClick={() => checkReferencee(demoIdentifier)}
                >
                  Check Status
                </button>
                {demoInfo && (
                  <>
                    {demoInfo?.status === "ENDED" ? (
                      <div className="mt-3 l-3">
                        <p>
                          Meeting Concluded. Take a moment to rate the host.
                        </p>
                      </div>
                    ) : calculateDifferenceTodayPublic(
                        demoInfo?.schedule.scheduled_date +
                          "T" +
                          demoInfo?.schedule.scheduled_time,
                        "minutes"
                      ) >= 5 &&
                      (Object.keys(demoInfo?.meeting_details).length === 0 || demoInfo?.status === DEMO_STATUS_OPTIONS.ACCEPTED) ? (
                      <div className="mt-3 l-3">
                        <p>
                          The meeting is scheduled to start at{" "}
                          {convertTime(
                            convertUTCToLocal(
                              demoInfo.schedule.scheduled_date +
                                "T" +
                                demoInfo.schedule.scheduled_time
                            )[1]
                          )}
                          ,{" "}
                          {formatDate(
                            convertUTCToLocal(
                              demoInfo.schedule.scheduled_date +
                                "T" +
                                demoInfo.schedule.scheduled_time
                            )[0]
                          )}{" "}
                        </p>
                      </div>
                    ) : calculateDifferenceTodayPublic(
                        demoInfo?.schedule.scheduled_date +
                          "T" +
                          demoInfo?.schedule.scheduled_time,
                        "minutes"
                      ) <= 5 &&
                      calculateDifferenceTodayPublic(
                        demoInfo?.schedule.scheduled_date +
                          "T" +
                          demoInfo?.schedule.scheduled_time,
                        "minutes"
                      ) >= -30 &&
                      (Object.keys(demoInfo?.meeting_details).length === 0 || demoInfo?.status === DEMO_STATUS_OPTIONS.ACCEPTED)  ? (
                      <div className="mt-3 l-3">
                        <p>
                          The Host must start the meeting before you can join.{" "}
                          <br />
                          Please try again in a moment.{" "}
                        </p>
                      </div>
                    ) : calculateDifferenceTodayPublic(
                        demoInfo?.schedule.scheduled_date +
                          "T" +
                          demoInfo?.schedule.scheduled_time,
                        "minutes"
                      ) <= 5 &&
                      Object.keys(demoInfo?.meeting_details).length !== 0 && demoInfo?.status !== DEMO_STATUS_OPTIONS.ACCEPTED ? (
                      <button
                        className="btn btn-primary mt-3 join-demo-button"
                        onClick={async () => {
                          setDemoId(demoInfo?.id);
                          const data = {
                            apikey: process.env.REACT_APP_VONAGE_API_KEY,
                            sessionId: demoInfo?.meeting_details?.session_id,
                            token: demoInfo?.meeting_details?.guest_token,
                          };
                          localStorage.setItem(
                            "videoToken",
                            JSON.stringify(data)
                          );
                          setVideoCredentials(data);
                          navigate(`/demo/waiting-room/${demoInfo?.id}`, {
                            state: {
                              eventDetail: demoInfo,
                              direct: "yes",
                            },
                          });
                        }}
                      >
                        Join Demo
                      </button>
                    ) : calculateDifferenceTodayPublic(
                        demoInfo?.schedule.scheduled_date +
                          "T" +
                          demoInfo?.schedule.scheduled_time,
                        "minutes"
                      ) < -30 ? (
                      <div className="mt-3 l-3">
                        <p>
                          We're sorry! It looks like your Host was not able to
                          make it.
                          <br />
                          <a href="https://murphydoor.com/pages/showrooms">
                            Please try scheduling again.
                          </a>
                        </p>
                      </div>
                    ) : null}
                    {/* {Object.keys(demoInfo?.meeting_details).length === 0 ||
                    demoInfo?.status === "ENDED" ? (
                      <>Meeting Concluded</>
                    ) : (
                      <button
                        className="btn btn-primary mt-3 join-demo-button"
                        onClick={() => {
                          setDemoId(demoInfo?.id);

                          const data = {
                            apikey: process.env.REACT_APP_VONAGE_API_KEY,
                            sessionId: demoInfo?.meeting_details?.session_id,
                            token: demoInfo?.meeting_details?.guest_token,
                          };
                          localStorage.setItem(
                            "videoToken",
                            JSON.stringify(data)
                          );
                          setVideoCredentials(data);
                          navigate("/demo/waiting-room", {
                            state: {
                              eventDetail: demoInfo,
                              direct: "yes",
                            },
                          });
                        }}
                      >
                        Join Demo
                      </button>
                    )} */}
                  </>
                )}
              </div>
              {demoInfo?.status === "ENDED" ? (
                <div className="rating_wrapper">
                  <Formik
                    initialValues={{
                      rating: "",
                      guest_review_note: "",
                    }}
                    onSubmit={async (e) => {
                      try {
                        const res = await hostGuestRating(
                          queryParams.get("demo"),
                          {
                            rating: parseInt((parseFloat(e.rating) / 5) * 100),
                            guest_review_note: e.guest_review_note,
                          }
                        );
                        setResponseMessage(res.data.message);
                      } catch (error) {
                        setLoading(true);
                      }
                    }}
                  >
                    {({ setFieldValue }) => (
                      <Form>
                        <div className="rating_elements">
                          <Rating
                            transition
                            allowFraction
                            initialValue={0}
                            className="mt-3"
                            onClick={(e) => setFieldValue("rating", e)}
                          />
                          <Field
                            as="textarea"
                            name="guest_review_note"
                            rows={3}
                            placeholder="Add a comment"
                            className="form-control mt-3"
                          />
                          <button
                            className="btn btn-outline-primary btn-sm mt-3"
                            type="submit"
                          >
                            Rate
                          </button>
                          {responseMessage ? (
                            <Alert className="mt-3" variant="success">
                              {responseMessage}
                            </Alert>
                          ) : null}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : null}
            </div>
          </div>
          {loading === true ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="grow" color="#57b0de" />
              <Spinner animation="grow" color="#57b0de" />
              <Spinner animation="grow" color="#57b0de" />
            </div>
          ) : (
            <div className="meeting-status-wrapper hide mt-3">
              {demoInfo ? (
                <div>
                  {" "}
                  <div className="meeting-status">
                    <strong>Store:</strong> {demoInfo?.store.name}
                    <br />
                    <strong>Meeting Status:</strong> {demoInfo?.status}
                    <br />
                    {demoInfo?.members ? (
                      <>
                        {demoInfo?.members.guest_detail ? (
                          <>
                            <strong>Guest: </strong>{" "}
                            {demoInfo?.members.guest_detail.full_name}
                            <br />
                          </>
                        ) : null}
                        {demoInfo?.members.host_detail ? (
                          <>
                            <strong>Host: </strong>{" "}
                            {demoInfo?.members.host_detail.first_name}{" "}
                            {(demoInfo?.members.host_detail.last_name).charAt(
                              0
                            )}
                            .
                            <br />
                          </>
                        ) : null}
                      </>
                    ) : null}
                    {demoInfo?.schedule?.scheduled_date !== null ? (
                      <>
                        <strong>Date: </strong>
                        {formatDate(
                          convertUTCToLocal(
                            demoInfo.schedule.scheduled_date +
                              "T" +
                              demoInfo.schedule.scheduled_time
                          )[0]
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    <br />
                    {demoInfo?.schedule?.scheduled_date !== null ? (
                      <>
                        <strong>Time: </strong>
                        {convertTime(
                          convertUTCToLocal(
                            demoInfo.schedule.scheduled_date +
                              "T" +
                              demoInfo.schedule.scheduled_time
                          )[1]
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="meeting-error-response"></div>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default MeetingStatusPublic;
